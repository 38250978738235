import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {finalize} from "rxjs/operators";
import {SubscriptionComponent} from "../shared/subscription-component";
import {AuthenticationService} from "../security/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-login-pin',
    templateUrl: './login-pin.component.html',
    styleUrls: ['./login-pin.component.scss']
})
export class LoginPinComponent extends SubscriptionComponent implements OnInit {

    form: UntypedFormGroup;
    hashCtrl = new UntypedFormControl(null, [Validators.required]);
    pinCtrl = new UntypedFormControl(null, [Validators.required]);
    error: any;
    to: string;

    waiting = false;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,
                private router: Router,
                private authenticationService: AuthenticationService) {
        super();
        this.form = formBuilder.group({
            username: this.hashCtrl,
            password: this.pinCtrl
        });

    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.hashCtrl.reset(params.hash);
        });
        this.route.queryParams.subscribe(params => {
            this.to = params.to;
        });
        this.authenticationService.user().subscribe(user => {
            if (user)
                this.router.navigate([this.to || '/']).then();
        });
    }

    login() {
        this.waiting = true;
        this.wrapSubscription(
            this.authenticationService.login(this.hashCtrl.value, this.pinCtrl.value, error => this.error = error)
                .pipe(finalize(() => this.waiting = false))
                .subscribe(user => {
                    if (user) {
                        this.router.navigate([this.to || '/']).then();
                    }
                })
        );
    }


}
