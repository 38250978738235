import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {RolePermissionView} from "./model";
import {UserView} from "./model/user";
import {Permissions} from "./permissions";

@Injectable()
export class AuthenticationService {

    _modules = new ReplaySubject<string[]>(1);

    _user = new ReplaySubject<UserView>(1);

    _permissions = new ReplaySubject<RolePermissionView[]>(1);

    constructor(private http: HttpClient) {
        this._user.subscribe(user => {
            if (!!user) {
                this.http.get<string[]>(`${environment.apiRoot}/auth/modules`)
                    .subscribe(modules => this._modules.next(modules));
                this.http.get<RolePermissionView[]>(`${environment.apiRoot}/auth/permissions`)
                    .subscribe(permissions => this._permissions.next(permissions));
            }
        });
        this.http.get<UserView>(`${environment.apiRoot}/auth`)
            .subscribe(user => this._user.next(user));
    }

    permissions(): Observable<RolePermissionView[]> {
        return this._permissions.asObservable();
    }

    modules(): Observable<string[]> {
        return this._modules.asObservable();
    }

    logout(onLogout: () => void = null) {
        this.http.post(`${environment.apiRoot}/logout`, {}).subscribe(
            () => {
            },
            () => {
            },
            () => {
                this._modules.next(null);
                this._permissions.next(null);
                this._user.next(null);
                if (onLogout)
                    onLogout();
            }
        );
    }

    user(evadeCache = false): Observable<UserView> {
        return evadeCache ?
            this.fetchUser(new HttpHeaders(), null) :
            this._user.asObservable();
    }

    login(username: string, password: string, onError: (errorResponse) => void = null): Observable<UserView> {
        const hash = btoa(`${username}:${password}`);
        return this.fetchUser(new HttpHeaders().append('Authorization', `Basic ${hash}`), onError);
    }

    private fetchUser(headers: HttpHeaders, onError: (errorResponse) => void): Observable<UserView> {
        return this.http
            .get<UserView>(`${environment.apiRoot}/auth`, {
                withCredentials: true,
                headers: headers
            })
            .pipe(
                catchError(e => {
                    if (onError)
                        onError(e);
                    return throwError(e);
                }),
                tap(user => this._user.next(user))
            );
    }

    runas(login: string, onError: (errorResponse) => void): Observable<UserView> {
        return this
            .http.post<UserView>(`${environment.apiRoot}/user/runas/${login}`, {})
            .pipe(
                catchError(e => {
                    if (onError)
                        onError(e);
                    return throwError(e);
                }),
                tap(user => {
                    if (user)
                        this._user.next(user);
                })
            );
    }

    hasPermission(permission: string): Observable<boolean> {
        return this
            .permissions()
            .pipe(map(permissions => Permissions.hasPermission(permissions, permission)));
    }

    emitUserChange(user: UserView) {
        this._user.next(user);
    }

}

