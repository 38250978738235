import {Directive, ElementRef} from '@angular/core';

@Directive({selector: '[hasAutofocus]'})
export class HasAutofocusDirective {

    constructor(private el: ElementRef) {
        setTimeout(() => el.nativeElement && el.nativeElement.focus && el.nativeElement.focus());
    }

}
