import {RolePermissionView} from "./model";

export class Permissions {

    static PERMISSION_PATTERN = /([^:]+):([^:]+):([^:]+)/;

    static hasAnyPermission(permissions: RolePermissionView[], permission: string[]): boolean {
        return !!permission && permission.some(p => Permissions.hasPermission(permissions, p));
    }

    static hasAllPermissions(permissions: RolePermissionView[], permission: string[]): boolean {
        return !!permission && !permission.some(p => !Permissions.hasPermission(permissions, p));
    }

    static hasPermission(permissions: RolePermissionView[], permission: string): boolean {
        if (!permissions || permissions.length === 0) {
            return false;
        }

        const m = permission.match(Permissions.PERMISSION_PATTERN);
        if (!m) {
            console.warn(`Permission '${permission}' is not recognized`);
            return false;
        }

        const _domainObject = m[1];
        const _targetId = m[2];
        const _permission = m[3];

        return permissions
            .some(p => {
                return [_domainObject, '*'].indexOf(p.domainObject) !== -1 &&
                    [_targetId, '*'].indexOf(p.targetId) !== -1 &&
                    [_permission, '*'].indexOf(p.permission) !== -1;
            });
    }

}
