<div class="mat-typography">
    <form [formGroup]="form" (submit)="login()">
        <div style="display: flex; align-items: center; margin-top: 2em; margin-bottom: 2em">
            <div style="flex: 1"></div>
            <div style="flex: 2; text-align: center" class="inner-all">
                <div>Introduza o seu pin de acesso</div>
                <div>
                    <mat-form-field>
                        <mat-hint *ngIf="error" class="cp-text-danger"><i class="fa fa-lock"></i> {{error?.error.message}}</mat-hint>
                        <input hasAutofocus [formControl]="pinCtrl" type="password" matInput>
                    </mat-form-field>
                </div>
                <div class="inner-t">
                    <button mat-raised-button color="accent" [disabled]="form.invalid || waiting">Entrar</button>
                </div>
            </div>
            <div style="flex: 1"></div>
        </div>
    </form>
</div>