import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "./error-service.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

export class PublicContentView {
    slug: string;
    content: string;
}

@Injectable()
export class PublicContentService {

    constructor(private http: HttpClient,
                private errorService: ErrorService) {

    }

    get(slug: string): Observable<PublicContentView> {
        return this.errorService.wrapCall(this.http.get<PublicContentView>(`${environment.apiRoot}/public/content/${slug}`));
    }

}