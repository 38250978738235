/* tslint:disable:no-string-literal */

import {ApplicationRef, ElementRef, Inject, Injectable, InjectionToken} from '@angular/core';
import {FormControl} from "@angular/forms";

export const RECAPTCHA_KEY = new InjectionToken<string>(null);

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  baseUrl = 'https://www.google.com/recaptcha/api.js';
  callbackName = 'RecaptchaServiceCallback';
  reaptchaId: string;
  element: ElementRef;
  control: FormControl;

  constructor(private app: ApplicationRef,
              @Inject(RECAPTCHA_KEY) private recaptchaKey: string) {
    this.reaptchaId = `recaptcha-${new Date().getTime()}`;
    window[this.callbackName] = () => {
      this.render();
    };
  }

  prepare(element: ElementRef, control: FormControl) {
    if (!element || !control)
      return;

    if (this.element) {
      this.element.nativeElement.parentNode.remove(this.element.nativeElement);
    }
    this.control = control;

    this.element = element;
    const fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById(this.reaptchaId)) {
      this.render();
      return;
    }

    const js = document.createElement('script');
    js.id = this.reaptchaId;
    js.src = `${this.baseUrl}?onload=${this.callbackName}&amp;render=explicit`;
    fjs.parentNode.insertBefore(js, fjs);
  }

  public render() {
    window['grecaptcha'].render(this.element.nativeElement, {
      sitekey: this.recaptchaKey,
      callback: (response: any) => {
        console.log('response:', response);
        this.control.reset(response);
        this.app.tick();
      }
    });
  }

  public reset() {
    if (window['grecaptcha'])
      window['grecaptcha'].reset();
  }

  public destroy() {

  }


}
