import {BrowserModule} from '@angular/platform-browser';
import {InjectionToken, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {SecurityModule} from "./security/security.module";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {SharedModule} from "./shared/shared.module";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentUtcDateAdapter} from "./shared/moment-utc-adapter";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDividerModule} from "@angular/material/divider";
import {ServicesModule} from "./services/services.module";
import {LoginModule} from "./login/login.module";
import {HttpVersionInterceptor} from "./shared/http-version.interceptor";
import {UiModule} from './ui/ui.module';

export const TINY_MCE_KEY: InjectionToken<string> = new InjectionToken<string>('');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    UiModule.forRoot(),
    SharedModule.forRoot(),
    SecurityModule.forRoot(),
    ServicesModule.forRoot(),
    LoginModule.forRoot(),
    MatButtonModule,
    SharedModule,
    MatExpansionModule,
    MatDividerModule,
    UiModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpVersionInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt'
    },
    {
      provide: TINY_MCE_KEY,
      useValue: '02m5u60nwolcxmv08onchn57mqrxl7fc81jlkht15u5qnn3p'
    },
    {
      provide: DateAdapter,
      useClass: MomentUtcDateAdapter,
      // useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true}
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

