import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../security/authentication.service";
import {throwError} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoginOptions} from "./login.service";
import {RecaptchaService} from "../services/recaptcha.service";
import {environment} from "../../environments/environment";
import {ErrorService} from "../services/error-service.service";
import {SubscriptionComponent} from "../shared/subscription-component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends SubscriptionComponent implements OnInit {

    recaptchaCtrl = new UntypedFormControl();
    usernameCtrl = new UntypedFormControl();
    passwordCtrl = new UntypedFormControl();
    recuperaEmail = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
    ]);

    succeeded = false;
    sending = false;

    @ViewChild('reCaptchaElement', {static: false})
    set reCaptchaElement(el: ElementRef) {
        this.recaptchaService.prepare(el, this.recaptchaCtrl);
    }

    form: UntypedFormGroup;
    recuperaForm: UntypedFormGroup;
    error: any;

    waiting = false;
    esqueci = false;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { options: LoginOptions },
                private http: HttpClient,
                private recaptchaService: RecaptchaService,
                private authenticationService: AuthenticationService,
                public dialogRef: MatDialogRef<LoginComponent>,
                private errorService: ErrorService,
                private router: Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private formBuilder: UntypedFormBuilder) {
        super();
        this.form = formBuilder.group({
            username: this.usernameCtrl,
            password: this.passwordCtrl
        });

        this.recuperaForm = this.formBuilder.group({
            email: this.recuperaEmail,
            recaptcha: this.recaptchaCtrl
        });
    }

    ngOnInit() {
    }

    login() {
        this.waiting = true;
        this.wrapSubscription(
            this.authenticationService.login(this.usernameCtrl.value, this.passwordCtrl.value, error => this.error = error)
                .pipe(finalize(() => this.waiting = false))
                .subscribe(user => {
                    if (user) {
                        this.dialogRef.close();
                        if (this.dialogData.options.onSuccess) {
                            this.dialogData.options.onSuccess.apply(user);
                        }
                    }
                })
        );
    }

    enviarEsqueci() {
        this.succeeded = false;
        this.error = undefined;
        this.sending = true;
        this.wrapSubscription(
            this.errorService.wrapCall(this.http.post(`${environment.apiRoot}/public/registo/recuperar`, this.recuperaForm.value))
                .pipe(
                    catchError(e => {
                        this.error = e;
                        return throwError(e);
                    }),
                    finalize(() => {
                        this.recaptchaCtrl.reset();
                        this.sending = false;
                    })
                )
                .subscribe(() => this.succeeded = true)
        );
    }

    backToLogin() {
        this.usernameCtrl.reset(this.recuperaEmail.value);
        this.esqueci = false;
        this.succeeded = false;
    }

    recuperar() {
        this.recuperaEmail.reset(this.usernameCtrl.value);
        this.succeeded = false;
        this.esqueci = true;
    }

}
