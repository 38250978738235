<form (submit)="ok()" [formGroup]="form">
    <div class="mat-typography" mat-dialog-content>
        <div [innerHTML]="_prompt"></div>
        <mat-form-field style="width: 100%">
            <input matInput id="text" [placeholder]="_placeholder" formControlName="text" #control [matAutocomplete]="autoInput"/>
        </mat-form-field>
        <mat-autocomplete #autoInput="matAutocomplete">
            <mat-option *ngFor="let value of valueProvider | async" [value]="value">
              {{value}}
            </mat-option>
        </mat-autocomplete>
    </div>
    <div mat-dialog-actions style="display: flex">
        <span class="cp-filler"></span>
        <button [disabled]="!control.value" mat-button mat-raised-button tabindex="2">Ok</button>
        <button type="button" mat-button (click)="cancel()" mat-raised-button tabindex="-1">Cancelar</button>
    </div>
</form>
