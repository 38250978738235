import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'public'
  },
  { // hack para o registo
    path: 'registo/recuperar',
    pathMatch: 'full',
    redirectTo: 'public/registo/recuperar'
  },
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'testing', loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule)},
  {path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},
  {path: 'concursos', loadChildren: () => import('./concursos/concursos.module').then(m => m.ConcursosModule)},
  {path: 'gestao', loadChildren: () => import('./gestao/gestao.module').then(m => m.GestaoModule)},
  {path: 'perfil', loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule)},
  {path: 'runas', loadChildren: () => import('./runas/runas.module').then(m => m.RunasModule)},
  {path: 'juri', loadChildren: () => import('./juri/juri.module').then(m => m.JuriModule)},
  {path: 'messenger', loadChildren: () => import('./messenger/messenger.module').then(m => m.MessengerModule)},
  {path: 'consulta', loadChildren: () => import('./consulta/consulta.module').then(m => m.ConsultaModule)},
  {path: 'consulta-outros', loadChildren: () => import('./consulta-outros/consulta-outros.module').then(m => m.ConsultaOutrosModule)},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

