import {ModuleWithProviders, NgModule} from '@angular/core';
import {FirstLastPipe} from "./first-last.pipe";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {ServicesModule} from "../services/services.module";
import {MatButtonModule} from "@angular/material/button";
import {SecurityModule} from "../security/security.module";
import {SubscriptionComponent} from "./subscription-component";
import {AbstractFormComponent} from "./abstract-form.component";
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";
import {MatToolbarModule} from "@angular/material/toolbar";
import {LpadPipe} from "./lpad.pipe";
import {HasAutofocusDirective} from "./has-autofocus.directive";
import {ZoomDirective} from "./zoom.directive";
import {CookieService} from "./cookie-service";


@NgModule({
  declarations: [
    LpadPipe,
    FirstLastPipe,
    SubscriptionComponent,
    AbstractFormComponent,
    HasAutofocusDirective,
    ZoomDirective,
  ],
  exports: [
    LpadPipe,
    FirstLastPipe,
    HasAutofocusDirective,
    ZoomDirective
  ],
  imports: [
    ServicesModule,
    RouterModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    SecurityModule,
    EditorModule,
    MatToolbarModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
        CookieService
      ]
    };
  }

}
