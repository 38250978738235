import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'cp-input-dialog',
    templateUrl: './input-area-dialog.component.html',
    styleUrls: ['./input-area-dialog.component.scss']
})
export class InputAreaDialogComponent implements AfterViewInit {

    _prompt: string;
    _placeholder: string;

    @ViewChild('inputControl', {static: true})
    inputControl: ElementRef;
    form: UntypedFormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                private formBuilder: UntypedFormBuilder,
                private dialogRef: MatDialogRef<InputAreaDialogComponent>) {
        this._prompt = data.prompt;
        this._placeholder = data.placeholder;
        this.form = formBuilder.group({
            text: new UntypedFormControl(data.value)
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.inputControl.nativeElement.focus();
            this.inputControl.nativeElement.select();
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    ok() {
        this.dialogRef.close(this.form.controls.text.value);
    }

}
