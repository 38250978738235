import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, flatMap} from "rxjs/operators";

export interface HintProvider {
    getHintList(value: string): Observable<string[]>;
}

@Component({
    selector: 'app-autocomplete-dialog',
    templateUrl: './autocomplete-dialog.component.html',
    styleUrls: ['./autocomplete-dialog.component.scss']
})
export class AutocompleteDialogComponent implements AfterViewInit, OnInit {

    _prompt: string;
    _placeholder: string;

    @ViewChild('control', {static: true})
    inputControl: ElementRef;

    form: UntypedFormGroup;

    valueProvider: Observable<string[]>;
    _hintProvider: HintProvider;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                private formBuilder: UntypedFormBuilder,
                private dialogRef: MatDialogRef<AutocompleteDialogComponent>) {
        this._prompt = data.prompt;
        this._placeholder = data.placeholder;
        this._hintProvider = data.hintProvider;
        this.form = formBuilder.group({
            text: new UntypedFormControl(data.value)
        });
    }

    ngOnInit() {
        this.valueProvider = this.form.controls.text.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(150),
                flatMap(value => this._hintProvider.getHintList(value))
            );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.inputControl.nativeElement.focus();
            this.inputControl.nativeElement.select();
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    ok() {
        this.dialogRef.close(this.form.controls.text.value);
    }

}
