import {Component, OnInit} from '@angular/core';
import {APP_VERSION} from "../version";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    date = new Date();
    version = APP_VERSION;

    constructor() {
    }

    ngOnInit() {
    }

}


