import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserMenuComponent} from "./user-menu.component";
import {SecurityModule} from "../security/security.module";
import {HomeLinkComponent} from "./home-link.component";
import {DefaultToolbarComponent} from "./default-toolbar.component";
import {TitleService} from "./title.service";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {PageTitleComponent} from "./page-title.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {SharedModule} from "../shared/shared.module";
import {MatDividerModule} from "@angular/material/divider";
import {ConcursoDetalheComponent} from "./concurso-detalhe.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import { PublicContentComponent } from './public-content.component';
import { PasswordFormComponent } from './password-form.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import { SubmissaoListComponent } from './submissao-list.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import { SubmissaoDetalheComponent } from './submissao-detalhe.component';
import { SubmissaoDetalheItemComponent } from './submissao-detalhe-item.component';
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ConcursoFechoDialogComponent } from './concurso-fecho-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { LogViewerDialogComponent } from './log-viewer-dialog.component';
import { PedidoSuporteDialogComponent } from './pedido-suporte-dialog.component';
import { ConcursoShareDialogComponent } from './concurso-share-dialog.component';
import {MatDatepickerModule} from "@angular/material/datepicker";


@NgModule({
    declarations: [
        UserMenuComponent,
        HomeLinkComponent,
        DefaultToolbarComponent,
        PageTitleComponent,
        ConcursoDetalheComponent,
        PublicContentComponent,
        PasswordFormComponent,
        SubmissaoListComponent,
        SubmissaoDetalheComponent,
        SubmissaoDetalheItemComponent,
        ConcursoFechoDialogComponent,
        LogViewerDialogComponent,
        PedidoSuporteDialogComponent,
        ConcursoShareDialogComponent,
    ],
    exports: [
        UserMenuComponent,
        HomeLinkComponent,
        DefaultToolbarComponent,
        PageTitleComponent,
        ConcursoDetalheComponent,
        PublicContentComponent,
        PasswordFormComponent,
        SubmissaoListComponent,
        SubmissaoDetalheComponent
    ],
  imports: [
    CommonModule,
    SecurityModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    SharedModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule
  ]
})
export class UiModule {

    static forRoot(): ModuleWithProviders<UiModule> {
        return {
            ngModule: UiModule,
            providers: [
                TitleService
            ]
        };
    }

}
