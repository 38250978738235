import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {

    _prompt: string;

    constructor(@Inject(MAT_DIALOG_DATA) private data) {
        this._prompt = data.prompt;
    }

}
