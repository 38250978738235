<div class="inner-all">
    <form (submit)="login()" [formGroup]="form" *ngIf="!esqueci">
        <div style="display: flex; align-items: center">
            <div style="flex: 1"></div>
            <div style="flex: 5">Se ainda não tem um login <span class="cp-text-muted">(utilizador)</span>, deverá fazer um <a href="javascript: void(0)" (click)="dialogRef.close()" [routerLink]="['/public', 'registo']">Novo Registo</a>.</div>
            <div style="flex: 1"></div>
        </div>
        <div style="display: flex; align-items: center; flex-flow: column">
            <mat-form-field style="width: 100%">
                <input hasAutofocus aria-label="Username" type="text" matInput placeholder="Utilizador" name="username" [formControl]="usernameCtrl"/>
                <mat-hint *ngIf="error" class="cp-text-danger"><i class="fa fa-lock"></i> {{error?.error.message}}</mat-hint>
            </mat-form-field>
            <mat-form-field style="width: 100%">
                <input aria-label="Password" type="password" matInput placeholder="Palavra-passe" name="password" [formControl]="passwordCtrl"/>
            </mat-form-field>
            <div style="display: flex; align-items: center; margin-top: 1em; width: 100%">
                <button style="flex: 1" type="button" mat-raised-button [routerLink]="['/public', 'registo']"
                        [queryParams]="dialogData?.options?.params"
                        (click)="dialogRef.close()" color="primary">Novo Registo
                </button>
                <div>&nbsp;</div>
                <button style="flex: 1" [disabled]="waiting" mat-raised-button color="accent">Entrar</button>
            </div>
            <div style="display: flex; align-items: center; margin-top: 1em">
                <div style="flex: 3; text-align: center" class="cp-text-small">
                    <a class="cp-link" href="javascript: void(0)" (click)="recuperar()">Esqueci a minha password...</a>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="esqueci">
        <form (submit)="enviarEsqueci()" novalidate [formGroup]="recuperaForm" *ngIf="!succeeded">

            <mat-form-field hintLabel="E-Mail utilizado na altura do registo" style="width: 100%">
                <input matInput
                       hasAutofocus
                       placeholder="E-Mail"
                       [formControl]="recuperaEmail"/>
                <mat-error *ngIf="recuperaEmail.invalid">Tem que especificar um e-mail válido!</mat-error>
            </mat-form-field>

            <div style="display: flex" class="inner-t">
                <div style="flex: 1"></div>
                <div #reCaptchaElement></div>
                <div style="flex: 1"></div>
            </div>

            <div class="inner-t" style="display: flex; align-items: center">
                <div style="flex: 1">&nbsp;</div>
                <button color="warn" type="button" mat-raised-button (click)="esqueci = false">Cancelar</button>
                <div>&nbsp;</div>
                <button color="accent" mat-raised-button>Recuperar...</button>
            </div>

        </form>
        <div *ngIf="succeeded">
            <div class="alert alert-success">
                <i class="fa fa-check"></i> Por favor consulte a sua caixa de correio em <b>{{recuperaEmail.value}}</b>.
                <br/>
                <div style="display: flex; align-items: center">
                    <div style="flex: 1"></div>
                    <a href="javascript: void(0)" (click)="backToLogin()">Regressar ao Login</a>
                    <div style="flex: 1"></div>
                </div>
            </div>
        </div>
    </div>
</div>
