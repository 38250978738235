import {Injectable} from '@angular/core';
import {PromptDialogComponent} from './components/prompt-dialog.component';
import {InputDialogComponent} from './components/input-dialog.component';
import {AutocompleteDialogComponent, HintProvider} from "./components/autocomplete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {InputAreaDialogComponent} from "./components/input-area-dialog.component";

@Injectable()
export class DialogService {

    constructor(private dialog: MatDialog) {
    }

    public prompt(prompt: string, handler: (yesNo: boolean) => void) {
        this.dialog.open(PromptDialogComponent, {
            data: {prompt}
        }).afterClosed().subscribe(
            result => {
                if (handler) {
                    handler(result);
                }
            }
        );
    }

    input(prompt: string,
          handler: (value: string) => void,
          value = null,
          placeholder: string = null) {
        this.dialog.open(InputDialogComponent, {
            width: '30vw',
            data: {
                prompt: prompt,
                placeholder: placeholder,
                value: value
            }
        }).afterClosed().subscribe(
            result => {
                if (result && handler) {
                    handler(result);
                }
            }
        );
    }

    inputArea(prompt: string,
              handler: (value: string) => void,
              value = null,
              placeholder: string = null) {
        this.dialog.open(InputAreaDialogComponent, {
            width: '30vw',
            data: {
                prompt: prompt,
                placeholder: placeholder,
                value: value
            }
        }).afterClosed().subscribe(
            result => {
                if (result && handler) {
                    handler(result);
                }
            }
        );
    }

    autocomplete(prompt: string,
                 hintProvider: HintProvider,
                 handler: (value: string) => void,
                 value = null,
                 placeholder: string = null) {
        this.dialog.open(AutocompleteDialogComponent, {
            width: '30vw',
            data: {
                prompt: prompt,
                placeholder: placeholder,
                value: value,
                hintProvider: hintProvider
            }
        }).afterClosed().subscribe(
            result => {
                if (result && handler) {
                    handler(result);
                }
            }
        );
    }

}
